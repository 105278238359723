import * as React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SSRProvider from "react-bootstrap/SSRProvider";

import AdmissionsOverviewBottomMenu from "../../../../components/admissionsOverviewBottomMenu";
import DefaultHeader from "../../../../components/default-header";
import Footer from "../../../../components/footer";
import GdprPanel from "../../../../components/gdpr";
import Navigation from "../../../../components/navigation";
import OisFullRegistrationLoop from "../../../../components/forms/oisFullRegistrationLoop";
import PageQuote from "../../../../components/quote";
import Seo from "../../../../components/seo";
import SpeakWithAdmissions from "../../../../components/speakWithAdmissions";

import "../../../../styles/ois.scss";
import NewsletterSignup from "../../../../components/newsletterSignUp";

const Page = ({ data, location }) => {
  const shortcodes = {
    Row,
    Col,
  };

  return (
    <>
      <SSRProvider>
        <Navigation />
        <DefaultHeader
          type="xl"
          headline={data.mdx.frontmatter.headline}
          heroBackground={data.mdx.frontmatter.heroBackground}
          heroMobileBackground={data.mdx.frontmatter.heroMobileBackground}
          subHeadline={data.mdx.frontmatter.subheadline}
        />
        <div id="body" className="ois">
          <div id="intro">
            <Container fluid="lg">
              <Row className="justify-content-center">
                <Col lg={12}>
                  <Row className="white-bg py-md-5 py-4">
                    <Col className="p-4">
                      <MDXProvider components={shortcodes}>
                        <MDXRenderer>{data.mdx.body}</MDXRenderer>
                      </MDXProvider>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
          <div id="imageBreak" className="px-4 px-lg-0 d-none">
            <div class="image"></div>
          </div>
          <div id="signUp">
            <Container fluid="lg" className="pb-4 pb-xl-5">
              <Row className="pb-4 pb-xl-5">
                <Col>
                  <OisFullRegistrationLoop urlRef={location} />
                </Col>
              </Row>
            </Container>
          </div>
          <div id="quote">
            <PageQuote
              quote="The Meridian experience was about more than just getting a degree. It was an invitation to explore and connect deeply with what matters most in this world, and to get grounded in the fundamentals of what it means to be alive as a human being. By answering this call to adventure, I found much more than an education. I found myself."
              quoteAuthor="Joshua Holcomb"
              quoteType="alumni"
              quoteHeadshot="https://web-cdn.meridianuniversity.edu/site-content-images/info-sessions-and-events/joshua-holcombe-headshot.webp"
            />
          </div>
          <div id="signUp">
            <Container fluid="lg" className="py-4 py-xl-5"> 
              <Row className="py-4 py-xl-5 justify-content-center">
                <Col md={10} lg={6} className="mb-4 mb-lg-0">
                  <AdmissionsOverviewBottomMenu />
                </Col>
                <Col md={10} lg={6}>
                  <SpeakWithAdmissions menuType="bottom" />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <Footer />
        <GdprPanel />
        <NewsletterSignup />
      </SSRProvider>
    </>
  );
};

export const Head = ({ data }) => (
  <Seo
    title={data.mdx.frontmatter.metadata.Title}
    description={data.mdx.frontmatter.metadata.Description}
    pathname={data.mdx.frontmatter.metadata.Link}
    ogImage={data.mdx.frontmatter.metadata.ogImage}
    cardImage={data.mdx.frontmatter.metadata.cardImage}
  />
);

export const query = graphql`
  query OISPageQuery {
    mdx(fileAbsolutePath: { regex: "/info-sessions-and-events.mdx/" }) {
      frontmatter {
        heroBackground
        heroMobileBackground
        subheadline
        headline
        title
        metadata {
          Description
          Keywords
          Link
          Title
          ogImage
          cardImage
        }
      }
      body
    }
  }
`;

export default Page;
